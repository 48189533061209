exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-about-jsx": () => import("./../../../src/templates/About.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-bomb-page-index-jsx": () => import("./../../../src/templates/BombPage/index.jsx" /* webpackChunkName: "component---src-templates-bomb-page-index-jsx" */),
  "component---src-templates-contacts-page-index-jsx": () => import("./../../../src/templates/ContactsPage/index.jsx" /* webpackChunkName: "component---src-templates-contacts-page-index-jsx" */),
  "component---src-templates-donation-reports-index-jsx": () => import("./../../../src/templates/DonationReports/index.jsx" /* webpackChunkName: "component---src-templates-donation-reports-index-jsx" */),
  "component---src-templates-fa-qpage-index-jsx": () => import("./../../../src/templates/FAQpage/index.jsx" /* webpackChunkName: "component---src-templates-fa-qpage-index-jsx" */),
  "component---src-templates-fundraising-landing-page-index-jsx": () => import("./../../../src/templates/FundraisingLandingPage/index.jsx" /* webpackChunkName: "component---src-templates-fundraising-landing-page-index-jsx" */),
  "component---src-templates-home-page-index-jsx": () => import("./../../../src/templates/HomePage/index.jsx" /* webpackChunkName: "component---src-templates-home-page-index-jsx" */),
  "component---src-templates-other-pages-jsx": () => import("./../../../src/templates/OtherPages.jsx" /* webpackChunkName: "component---src-templates-other-pages-jsx" */),
  "component---src-templates-partners-page-index-jsx": () => import("./../../../src/templates/PartnersPage/index.jsx" /* webpackChunkName: "component---src-templates-partners-page-index-jsx" */),
  "component---src-templates-partnerships-index-jsx": () => import("./../../../src/templates/Partnerships/index.jsx" /* webpackChunkName: "component---src-templates-partnerships-index-jsx" */),
  "component---src-templates-payment-details-jsx": () => import("./../../../src/templates/PaymentDetails.jsx" /* webpackChunkName: "component---src-templates-payment-details-jsx" */),
  "component---src-templates-payment-result-page-index-jsx": () => import("./../../../src/templates/PaymentResultPage/index.jsx" /* webpackChunkName: "component---src-templates-payment-result-page-index-jsx" */),
  "component---src-templates-update-page-index-jsx": () => import("./../../../src/templates/UpdatePage/index.jsx" /* webpackChunkName: "component---src-templates-update-page-index-jsx" */),
  "component---src-templates-updates-page-index-jsx": () => import("./../../../src/templates/UpdatesPage/index.jsx" /* webpackChunkName: "component---src-templates-updates-page-index-jsx" */),
  "component---src-templates-vacancies-page-index-jsx": () => import("./../../../src/templates/VacanciesPage/index.jsx" /* webpackChunkName: "component---src-templates-vacancies-page-index-jsx" */),
  "component---src-templates-vacancy-page-index-jsx": () => import("./../../../src/templates/VacancyPage/index.jsx" /* webpackChunkName: "component---src-templates-vacancy-page-index-jsx" */)
}

