export const AVERAGE_DOLLAR_EXCHANGE_RATE = 40.1537;

export const MOBILE_VIEWPORT = 540;
export const TABLET_PORTRAIT_VIEWPORT = 820;
export const TABLET_LANDSCAPE_VIEWPORT = 1180;
export const LAPTOP_VIEWPORT = 1280;
export const DESKTOP_VIEWPORT = 1440;

export const MINE_SHELL_SIGNATURE_ID = 'OT6pWvOLSYu5O1DZGbT30A';
export const REACT_APP_DATOCMS_API_TOKEN = 'ffe175dcec0cd3b7efd4a3de95145a';

export const MONTH_NAMES = [
  { en: 'January', uk: 'Січень' },
  { en: 'February', uk: 'Лютий' },
  { en: 'March', uk: 'Березень' },
  { en: 'April', uk: 'Квітень' },
  { en: 'May', uk: 'Травень' },
  { en: 'June', uk: 'Червень' },
  { en: 'July', uk: 'Липень' },
  { en: 'August', uk: 'Серпень' },
  { en: 'September', uk: 'Вересень' },
  { en: 'October', uk: 'Жовтень' },
  { en: 'November', uk: 'Листопад' },
  { en: 'December', uk: 'Грудень' },
];
